import React from 'react'
import Img from 'gatsby-image'
import Paragraph from './paragraph'
import SlideInTop from '../utility/slideInTopAnimation'
import PropTypes from 'prop-types'

const TextImageRight = props => (
  <div className={`${props.background} py-8 md:py-20`}>
    <div className="section md:flex md:items-center md:space-x-16">
        <div className="md:w-1/2">
          <Paragraph title={props.titleOne} text={props.textOne} />
          <Paragraph title={props.titleTwo} text={props.textTwo} />
          {props.titleThree && (
            <Paragraph title={props.titleThree} text={props.textThree} />
          )}
          {props.titleFour && (
            <Paragraph title={props.titleFour} text={props.textFour} />
          )}
        </div>
        {props.fixed && (
          <div className="md:w-1/2">
            <SlideInTop>
              <Img className={props.className} fixed={props.fixed} style={props.style} alt={props.alt} />
            </SlideInTop>
          </div>
        )}
        {props.animatedIllustration && (
          <div className="md:w-1/2">
            <SlideInTop>{props.animatedIllustration}</SlideInTop>
          </div>
        )}
    </div>
  </div>
)

TextImageRight.propTypes = {
  background: PropTypes.string,
}

TextImageRight.defaultProps = {
  background: 'bg-white',
}

export default TextImageRight
