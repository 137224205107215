import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../../components/seo/seo'
import SiteNavigation from '../../components/header/siteNavigation'
import Section1 from '../../components/sections/section1'
import TextImageRight from '../../components/common/textWithRightImage'
import ProductsDisplay from '../../components/common/productsDisplay'
import ProductOnShow from '../../components/common/productOnShow'
import CommonFooter from '../../components/footer/commonFooter'
import StylishLinks from '../../components/common/stylishLinks'
import ElectronicsDevelopment from '../../illustrations/ProjectEngineeringGoalSetting'
import GeneralSignUp from '../../components/forms/generalSignUpForm'
import ProductInfoForm from '../../components/forms/productInfoForm'
import { useTranslation } from 'react-i18next'
import { StaticImage } from "gatsby-plugin-image"
import ElegantHeader from '../../components/header/elegantHeader'

const IOBoards = ({ data }) => {
  const { t } = useTranslation();

  return (
  <>
    <Seo
      title={t('IOBoards.seoTitle')}
      description={t('IOBoards.seoDescription')}
      seoImage={data.schedapotenza1.childImageSharp.resize.src}
    />
    <ElegantHeader
      title={t('IOBoards.headerTitle')}
      subtitle={t('IOBoards.headerSubtitle')}
      image={<StaticImage className="bg-white rounded-full" src="../../images/products/schedeIO/scheda_rele.png" width={400} alt="Scheda relè con ingressi e uscite digitali e analogici" />}
    />
    {/* <div className='maingrad pt-24 pb-20'>
      <div className='section'>
        <div className='md:flex md:items-center md:justify-between'>
          <div className='md:w-1/2'>
            <h1 className='font-semibold text-3xl md:text-5xl text-white'>{t('IOBoards.headerTitle')}</h1>
            <h2 className='text-xl font-semibold pt-12'>{t('IOBoards.headerSubtitle')}</h2>
          </div>
          <div className='md:w-1/2 text-center'>
            <StaticImage className="bg-white rounded-full" src="../../images/products/schedeIO/scheda_rele.png" width={400} alt="Scheda relè con ingressi e uscite digitali e analogici" />
          </div>
        </div>
      </div>
    </div> */}
    <Section1
      section1Title={t('IOBoards.section1Title')}
      section1Text={t('IOBoards.section1Text')}
    />
    <TextImageRight
      sectionHeight="is-medium"
      background="is-gradient-bottom-right"
      titleOne={t('IOBoards.introTitle')}
      textOne={t('IOBoards.intro')}
      // fixed={data.placeholder.childImageSharp.fixed}
      animatedIllustration={<ElectronicsDevelopment />}
      // style={{ width: '560px' }}
    />
    {/* <ProductsOnShow
      products={[1, 2, 3]}
      productImage={[
        data.schedapotenza8rele.childImageSharp.fixed,
        data.schedapotenza16rele.childImageSharp.fixed,
        data.schedapotenza24rele.childImageSharp.fixed,
      ]}
      imageWidth="350"
      productImageAlt={[
        t('IOBoards.alt1'),
        t('IOBoards.alt2'),
        t('IOBoards.alt3'),
      ]}
      intro={t('IOBoards.intro')}
      productTitle={[
        <>{t('IOBoards.product1')}</>,
        <>{t('IOBoards.product2')}</>,
        <>{t('IOBoards.product3')}</>,
      ]}
      productDescription={[
        <>{t('IOBoards.productDescription1')}</>,
        <>{t('IOBoards.productDescription2')}</>,
        <>{t('IOBoards.productDescription3')}</>,
      ]}
      productCTA={t('IOBoards.productCTA')}
    /> */}
    <ProductsDisplay
      sectionHeight=""
      product1={
        <ProductOnShow
          productImage={[data.schedapotenza8rele.childImageSharp.fixed]}
          imageWidth="280"
          productImageAlt={[t('IOBoards.alt1')]}
          productTitle={t('IOBoards.product1')}
          productDescription={t('IOBoards.productDescription1')}
          features={[1, 1, 1, 1, 1, 1, 1]}
          featureTitle={[
            <>{t('IOBoards.featureTitle1')}</>,
            <>{t('IOBoards.featureTitle2')}</>,
            <>{t('IOBoards.featureTitle3')}</>,
            <>{t('IOBoards.featureTitle4')}</>,
            <>{t('IOBoards.featureTitle5')}</>,
            <>{t('IOBoards.featureTitle6')}</>,
            <>{t('IOBoards.featureTitle7')}</>,
          ]}
          featureDesc={[
            <>{t('IOBoards.featureDesc1')}</>,
            <>{t('IOBoards.featureDesc2')}</>,
            <>{t('IOBoards.featureDesc3')}</>,
            <>{t('IOBoards.featureDesc4')}</>,
            <>{t('IOBoards.featureDesc5')}</>,
            <>{t('IOBoards.featureDesc6')}</>,
            <>{t('IOBoards.featureDesc7')}</>,
          ]}
          // tagText={t('Sensors.tagText')}
          productInfoCTA={t('IOBoards.productInfoCTA')}
          formInfo={<ProductInfoForm product="SC IO 8 relè"/>}
          productOrderCTA={t('IOBoards.productCTA')}
          emailSubject="subject=Ordine Schede I/O"
        />
      }
      product2={
        <ProductOnShow
          productImage={[data.schedapotenza16rele.childImageSharp.fixed]}
          imageWidth="280"
          productImageAlt={[t('IOBoards.alt2')]}
          productTitle={t('IOBoards.product2')}
          productDescription={t('IOBoards.productDescription2')}
          features={[1, 1, 1, 1, 1, 1, 1]}
          featureTitle={[
            <>{t('IOBoards.feature2Title1')}</>,
            <>{t('IOBoards.feature2Title2')}</>,
            <>{t('IOBoards.feature2Title3')}</>,
            <>{t('IOBoards.feature2Title4')}</>,
            <>{t('IOBoards.feature2Title5')}</>,
            <>{t('IOBoards.feature2Title6')}</>,
            <>{t('IOBoards.feature2Title7')}</>,
          ]}
          featureDesc={[
            <>{t('IOBoards.feature2Desc1')}</>,
            <>{t('IOBoards.feature2Desc2')}</>,
            <>{t('IOBoards.feature2Desc3')}</>,
            <>{t('IOBoards.feature2Desc4')}</>,
            <>{t('IOBoards.feature2Desc5')}</>,
            <>{t('IOBoards.feature2Desc6')}</>,
            <>{t('IOBoards.feature2Desc7')}</>,
          ]}
          // tagText={t('Sensors.tagText')}
          productInfoCTA={t('IOBoards.productInfoCTA')}
          formInfo={<ProductInfoForm product="SC IO 16 relè" />}
          productOrderCTA={t('IOBoards.productCTA')}
          emailSubject="subject=Ordine Schede I/O"
        />
      }
      product3={
        <ProductOnShow
          productImage={[data.schedapotenza24rele.childImageSharp.fixed]}
          imageWidth="280"
          productImageAlt={[t('IOBoards.alt3')]}
          productTitle={t('IOBoards.product3')}
          productDescription={t('IOBoards.productDescription3')}
          features={[1, 1, 1, 1, 1, 1, 1]}
          featureTitle={[
            <>{t('IOBoards.feature3Title1')}</>,
            <>{t('IOBoards.feature3Title2')}</>,
            <>{t('IOBoards.feature3Title3')}</>,
            <>{t('IOBoards.feature3Title4')}</>,
            <>{t('IOBoards.feature3Title5')}</>,
            <>{t('IOBoards.feature3Title6')}</>,
            <>{t('IOBoards.feature3Title7')}</>,
          ]}
          featureDesc={[
            <>{t('IOBoards.feature3Desc1')}</>,
            <>{t('IOBoards.feature3Desc2')}</>,
            <>{t('IOBoards.feature3Desc3')}</>,
            <>{t('IOBoards.feature3Desc4')}</>,
            <>{t('IOBoards.feature3Desc5')}</>,
            <>{t('IOBoards.feature3Desc6')}</>,
            <>{t('IOBoards.feature3Desc7')}</>,
          ]}
          // tagText={t('Sensors.tagText')}
          productInfoCTA={t('IOBoards.productInfoCTA')}
          formInfo={<ProductInfoForm product="SC IO 24 relè" />}
          productOrderCTA={t('IOBoards.productCTA')}
          emailSubject="subject=Ordine Schede I/O"
        />
      }
    />
    <StylishLinks
      animatedIllustration={<ElectronicsDevelopment />}
      linkOneTitle={<>{t('IOBoards.linkOneTitle')}</>}
      linkOneText={<>{t('IOBoards.linkOneText')}</>}
      // linkOne={<>{t('IOBoards.linkOne')}</>}
      // to1="/en/contatti"
      component={<GeneralSignUp />}
      buttonLabel={t('callToAction.subscribe')}
      buttonClass="is-primary"
      linkTwoTitle={t('IOBoards.linkTwoTitle')}
      linkTwoText={t('IOBoards.linkTwoText')}
      linkTwo={t('IOBoards.linkTwo')}
      to2="/contatti"
    />
    <CommonFooter />
  </>
)
}

export default IOBoards

export const ioQueryIT = graphql`
  query {
    schedapotenza1: file(relativePath: { eq: "icons/SchedaIO.png" }) {
      childImageSharp {
        fluid(maxWidth: 1250) {
          ...GatsbyImageSharpFluid
        }
        resize(width: 900, quality: 90) {
          src
        }
      }
    }
    schedapotenza8rele: file(
      relativePath: { eq: "products/schedeIO/SchedaIO_8rele.png" }
    ) {
      childImageSharp {
        fixed(width: 350) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    schedapotenza16rele: file(
      relativePath: { eq: "products/schedeIO/SchedaIO_16rele.png" }
    ) {
      childImageSharp {
        fixed(width: 350) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    schedapotenza24rele: file(
      relativePath: { eq: "products/schedeIO/SchedaIO_24rele.png" }
    ) {
      childImageSharp {
        fixed(width: 350) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    scheda16: file(relativePath: { eq: "products/schedeIO/scheda16rele.JPG" }) {
      childImageSharp {
        fixed(width: 280) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
