import React from 'react'
import SlideInLeft from '../utility/slideInLeftAnimation'
import SlideInBottom from '../utility/slideInBottomAnimation'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import Card from '../common/card'

const Section1 = props => (
  <div
    className={`relative`}
  >
    {props.fluid && (
      <Img
        fluid={props.fluid}
        style={{
          position: 'absolute',
          overflow: 'hidden',
        }}
        imgStyle={{
          width: '100%',
          height: '100%',
          opacity: '0.05',
        }}
        className="imagewrapper"
        alt={props.alt}
      />
    )}
    <div className="section">
      <div className="grid grid-cols-1 py-12 md:py-36">
        <div className="md:w-3/5">
          <SlideInLeft>
            <h2 className="text-5xl font-semibold">
              {props.section1Title}
            </h2>
          </SlideInLeft>
        </div>
        <div className="md:w-3/5 mt-10 place-self-end">
          <SlideInBottom>
            <Card
              cardSubtitle={props.section1Text}
            />
              {/* <p>{props.section1Text}</p> */}
          </SlideInBottom>
        </div>
      </div>
    </div>
  </div>
)

export default Section1
